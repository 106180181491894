<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="please choose" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Whistle User Recharge Agreement</h2>
		<p>This Whistle User Recharge Agreement (hereinafter referred to as "this Agreement") is concluded between you and CLICK ADVERTISING & MEDIA PTE. LTD. (hereinafter referred to as "the Company"), and this Agreement has contractual effect. In order to protect your legitimate rights and interests, please read carefully and fully understand the contents of each clause before agreeing and accepting all the terms of this Agreement, especially the clauses that exempt or limit the Company's liability (hereinafter referred to as "disclaimer clauses"), your Clauses that limit rights (hereinafter referred to as "restrictive clauses"), clauses that stipulate dispute resolution methods and jurisdiction. The foregoing disclaimers, limitations, dispute resolution methods and other important terms will be highlighted in bold or in other reasonable ways to remind you that your confirmation of these terms may cause you to be passive, inconvenient and lose under certain circumstances. Please You must read the preceding terms before confirming your agreement to this agreement or before using our company's software services. </p>
		<p>If you are under 18 years old, please read this agreement accompanied by your legal guardian, and obtain the guardian's consent and approval for your use of the services provided by our company and payment of fees to our company. You can use our company's software services only after complying with all the terms of this Agreement, "Personal Information Protection Policy", "Children's User Agreement" and "Children's Personal Information Protection Policy". If you are the legal guardian of a minor. If you are a minor under 14 years old (inclusive) or his legal guardian. </p>
		<p>If you have any comments or suggestions about this agreement or our company's software services, you can contact our company's customer service department as agreed in this agreement, and we will help you. Once you click the "Agree" button, "Accept" button or install, use, register or otherwise access our services, you are deemed to have read and understood the entire content of this Agreement in detail and agree to abide by the provisions of this Agreement. So please be sure to read this agreement carefully and completely before clicking. </p>
		<h2>Article 1 Service Rules</h2>
		<p>1. "Star Beans" are virtual props provided to you by this platform for related consumption on this platform. You can use "Star Beans" to purchase virtual gifts and other products or services on this platform (specifically, you can purchase For products and services, please check the corresponding product and service page descriptions), and give the purchased virtual gifts to the anchor or platform creator. However, "Star Beans" cannot be exchanged for RMB under any circumstances. Please be sure to purchase the corresponding amount of "Star Beans" according to your actual needs. </p>
		<p>2. During the recharge process, please be sure to carefully confirm your account and carefully select the relevant operation options. If your own rights and interests are damaged due to factors such as incorrect input of your account number, improper operation or lack of understanding or full understanding of the recharge and billing methods, etc., your rights and interests will be damaged. It is your own responsibility and this platform will not make any compensation or compensation. </p>
		<p>3. Please be sure to use the official recharge method designated by this platform to recharge. If you use a recharge method that is not specified by this platform or an illegal method to recharge, this platform does not guarantee that the recharge will be completed smoothly or correctly, and it may cause other risks. If you or any third party's rights and interests are damaged as a result, you are responsible for your own responsibility. This platform will not make any compensation or compensation for handling and bear corresponding responsibilities. At the same time, this platform reserves the right to suspend part or all of your account qualifications, freeze your account balance, ban your account for a short term or permanently, and prohibit you from using various services at any time. The right to recharge services. </p>
		<p>4. "Xingdou" recharge is a one-time service provided by this platform based on business operation needs. "Xingdou" is not a deposit, advance payment or other goods or property rights that can be returned without reason. After the user's recharge is successful, the platform's recharge service has been provided. The platform will no longer provide reverse exchange or transfer services, and the platform will not provide refund services without legitimate reasons (legitimate reasons refer to the platform stopping operations or stopping providing services to users). Provide "Xingdou" and all "Xingdou" related functions and services, or laws and regulations clearly stipulate that this platform should be returned), please be sure to choose carefully before specific operations. </p>
		<p>5. If the platform discovers a processing error caused by a system failure or any other reason, whether it is beneficial to the platform or to you, the platform has the right to correct it after notifying you by email, text message or other reasonable means. The error. If this measure results in the actual number of "Star Beans" you receive being less than the "Star Beans" you should receive, the platform will make up the difference to your account as soon as possible after confirming the processing error. If the error causes you to actually receive more "Star Beans" than you should have received, the Platform has the right to deduct the difference directly from your account regardless of the nature and cause of the error. </p>
		<p>6. According to the company's product functions, the virtual props purchased by your recharge account on other products and platforms operated by the company (hereinafter referred to as "associated platforms") may be combined with the "Star Beans" you purchased on this platform. have the same scope of use. At this time, your use of virtual props from associated platforms on this platform is also subject to this agreement (regardless of whether the aforementioned virtual props from associated platforms are called "Xingdou" or other names on this platform); conversely, your Star If Beans are used on related platforms through the above methods, your use of "Star Beans" on related platforms will also be governed by the related platform recharge agreement and relevant rules and terms. </p>
		<p>7. The channel providers corresponding to each recharge method may set relevant recharge channel handling fees according to their standards and charge them when users recharge. Please be sure to pay attention to the channel provider service fees for each recharge method and choose one according to your own needs. Recharge method. </p>
		<p>8. "Xingdou" cannot be reversely converted into RMB after purchase, and the platform will not provide refund services without legitimate reasons as stipulated in this agreement. </p>
		<h2>Article 2 Rights Statement</h2>
		<p>1. You understand and agree that this platform has the right to set transaction-related matters from time to time based on transaction security and other considerations, including but not limited to transaction limits, number of transactions, etc. </p>
		<p>2. If the recharge process involves related services provided by a third party, in addition to complying with this agreement, you also need to agree and abide by the third party's agreement and relevant rules. In any case, for this third party Disputes arising from the third party and the related services provided by it shall be resolved by you and the third party yourself. This platform does not need to bear any responsibility to you or the third party in this regard. </p>
		<p>3. Any loss or liability arising from the inability of this platform to provide "Xingdou" purchasing services due to your own reasons or any errors in providing "Xingdou" purchasing services is your sole responsibility and this platform will not bear any responsibility. Responsibilities, including but not limited to:</p>
		<ul>
		<li>• (1) Opposing the basic principles established by the Constitution;</li>
		<li>• (2) Because your Whistle account is invalid, lost, or blocked;</li>
		<li>• (3) Loss or liability caused by your bound third-party payment institution account, including your use of an uncertified third-party payment account or your use of a third-party payment account other than your own. Third-party payment accounts have been frozen or seized;</li>
		<li>• (4) Property damage caused by you telling others your password;</li>
		<li>• (5) Property damage caused by your personal intention or gross negligence. </li>
		</ul>
		<p>4. When you are unable to use the platform’s recharge service or lose any of your virtual property due to the following conditions, you agree that the platform is not liable for damages, including but not limited to:</p>
		<ul>
		<li>• (1) During the system downtime maintenance, upgrade, and adjustment period announced on this platform;</li>
		<li>• (2) Telecom communication or equipment fails and data transmission cannot be carried out;</li>
		<li>• (3) Due to force majeure factors such as typhoons, earthquakes, tsunamis, floods, power outages, wars, terrorist attacks, government regulations, etc., the platform system is blocked and unable to perform business;</li>
		<li>• (4) Service interruption or delay caused by hacker attacks, technical adjustments or failures of the telecommunications department, website upgrades, related third-party problems, etc. </li>
		</ul>
		<p>5. Under no circumstances shall this platform be liable for any indirect, consequential, punitive, incidental, special or punitive damages. The total liability of this platform to you, regardless of the reason or mode of conduct, will never exceed the fees paid by you for using the recharge services provided by this platform. </p>
			
		<h2>Article 3 Penalty Rules</h2>
		<p>1. If any of the following circumstances occurs, this platform has the right to interrupt or terminate the provision of network services to you under this agreement at any time (without notifying you separately), and does not need to bear any liability due to the interruption or termination of the recharge service. Any liability for losses caused to you or any third party:</p>
		<ul>
		<li>• (1) The personal information you provide is untrue;</li>
		<li>• (2) You violate this Agreement, the "User Service Agreement", or other user management regulations of this platform;</li>
		<li>• (3) You purchase and use "Xingdou" for non-personal purposes such as profit and business. </li>
		</ul>
		<p>2. When the user uses the recharge service provided by this platform, if there is any suspected crime, violation of laws and regulations, violation of socialist morals, violation of the "Whistle Software License and User Agreement", this agreement or other platform's obligations on users According to the management regulations, this platform has the right to decide to temporarily or permanently ban your account depending on the nature and severity of your behavior. After the account is banned and before the ban is lifted (if any), the remaining "Star Beans" on your account will be temporarily frozen or completely deducted and cannot be used to purchase products and/or services on this platform. At the same time, the "Star Beans" you purchased will not be refunded. beans” cash value. </p>
			
		<h2>Article 4 Dispute Handling</h2>
		<p>1. The place where this agreement is signed is Nanshan District, Shenzhen City, Guangdong Province, the People's Republic of China. </p>
		<p>2. The establishment, effectiveness, performance, interpretation and dispute resolution of this agreement shall be governed by the laws of the mainland of the People's Republic of China. </p>
		<p>3. If any dispute or dispute arises between you and the Company due to this Agreement, it should first be resolved through friendly negotiation; if the negotiation fails, you and the Company agree to submit the dispute or dispute to the Shenzhen Court of International Arbitration for arbitration. and agree to use the arbitration rules of the arbitration court. </p>
		<p>4. The titles of all clauses in this agreement are only for the convenience of reading. They have no actual meaning and cannot be used as a basis for interpreting the meaning of this agreement. </p>
			
		<h2>Article 5 Others</h2>
		<p>1. In order to provide you with better services, or based on updates and changes in legal regulations, requirements of competent authorities, business development and other factors, this platform will revise the content of this agreement in a timely manner, and such revisions will be immediately announced. Effectively, this platform will issue an updated version through this platform. You can check the latest version of the agreement terms at any time through the "Xingdou" recharge page. If you continue to use the recharge services provided by this platform after the content of this agreement is revised, it will be deemed that you agree to the latest revised content of the agreement; otherwise, please stop recharging immediately. </p>
		<p>2. You understand and agree that the recharge services provided by this platform are provided according to the status quo that the existing technology and conditions can achieve. This platform will do its best to provide you with recharge services to ensure the continuity and consistency of the recharge services. safety. However, you also know and acknowledge that this platform cannot foresee and prevent technical and other risks at any time or all the time, including but not limited to service interruptions and inability to be used normally due to force majeure, network reasons, third-party service defects, third-party websites, etc. Recharge services and other losses and risks; in addition, in addition to the aforementioned circumstances, this platform has the right to interrupt, suspend or terminate the recharge services under this agreement at any time depending on transaction security, operation planning and other specific circumstances, and will announce it on this platform page or Notify users in other appropriate ways (without notifying you separately). </p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/RechargeAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/RechargeAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/RechargeAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/RechargeAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>